<!-- 大货单详情 - 已完成的订单详情 -->
<template>
  <div class="container">
    <div class="container_content">
      <!-- 左侧图片 -->
      <div class="container_content_left">
        <img :src="currentImgSrc" alt="">
        <div class="container_content_left_imgs">
          <img v-for="(img, index) in imgList" :key="index" :src="img" @click="changeImg(img)" :class="{ 'container_content_left_imgs_isSelected': img === currentImgSrc }" alt="">
        </div>
        <div :class="fileList.length>0?'container_content_left_btn':'container_content_left_btnNo'" @click="downloadDocNew('1')" >
          Download <span v-if="fileList.length>0">:{{fileList[0].name}}</span>
        </div>
        <div :class="fileList.length>1?'container_content_left_btn':'container_content_left_btnNo'" @click="downloadDocNew('2')" >
          Download <span v-if="fileList.length>1">:{{fileList[1].name}}</span>
        </div>
        <div class="container_content_left_btn" @click="$router.go(-1)" style="margin-top: 10px;">
          Back
        </div>
      </div>

      <!-- 右侧 -->
      <div class="container_content_right">

        <!-- 大货单详情  Official Order-->
        <div class="container_content_right_sampleOrder requirement">

          <!-- 标题 -->
          <div class="container_content_right_sampleOrder_titleBox requirement_titleBox">
            <div class="container_content_right_sampleOrder_titleBox_title">{{
              order.clientRequirement?.productName
            }}
            </div>
            <!-- <div class="container_content_right_sampleOrder_titleBox_quantity">
              <span>Quantity</span>{{ order.clientRequirement?.quantity }}
            </div> -->
          </div>

          <!-- 需求详情 -->
          <div class="container_content_right_sampleOrder_details" v-if="order.orderId" v-show="isShowRequirementDetail">
            <div class="container_content_right_sampleOrder_details_list" v-for="item in keys" :key="item.key">
              <div class="container_content_right_sampleOrder_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_sampleOrder_details_list_value">{{ getDisplayedValue(item) }}</div>
            </div>
            <!--            <div class="container_content_right_sampleOrder_details_list file">-->
            <!--              <div class="file_left">-->
            <!--                <div class="container_content_right_sampleOrder_details_list_title">Original Artwork-->
            <!--                </div>-->
            <!--                <div class="container_content_right_sampleOrder_details_list_value">Design draft.ai-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="file_download" @click="getClientArtByRequirementIdMethod">Download</div>-->
            <!--            </div>-->
          </div>

          <div class="container_content_right_sampleOrder_details" v-for="(product, productIndex) in productInfoList" :key="productIndex">
            <div class="container_content_right_sampleOrder_details_title">
              {{ product.productName }}
            </div>
            <div class="container_content_right_sampleOrder_details_list" v-for="item in keys" :key="item.key">
              <div class="container_content_right_sampleOrder_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_sampleOrder_details_list_value">{{ getDisplayedValue2(productIndex, item) }}</div>
            </div>
            <!--            <div class="container_content_right_sampleOrder_details_list file">-->
            <!--              <div class="file_left">-->
            <!--                <div class="container_content_right_sampleOrder_details_list_title">Original Artwork-->
            <!--                </div>-->
            <!--                <div class="container_content_right_sampleOrder_details_list_value">Design draft.ai-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="file_download" @click="getClientArtByRequirementIdMethod">Download</div>-->
            <!--            </div>-->
          </div>

          <!-- 需求单通用数据 -->
          <div class="container_content_right_sampleOrder_details commonData">
            <div class="container_content_right_sampleOrder_details_list commonData_list" v-for="(item, index) in commonData" :key="item.key" v-if="order.orderId">
              <div class="container_content_right_sampleOrder_details_list_title">{{ getCommonTitle(item) }}</div>
              <div class="container_content_right_sampleOrder_details_list_value">{{ getDisplayedValue(item) }}</div>
            </div>
          </div>

          <!-- Expand/Collapse -->
          <!-- <div class="container_content_right_sampleOrder_expand" @click="showRequirementDetail">
            <div class="container_content_right_sampleOrder_expand_box">
              <span>Expand</span>
              <img src="../../assets/images/customer/Expand.png" alt="">
            </div>
          </div> -->
        </div>

        <!-- 报价单  quotation-->

      </div>
    </div>

    <div class="container_main" v-if="quotationId != null">

    <div class="container_main_right">
    <div class="container_main_right_requirement quotation">
  <!-- 标题 -->
  <div class="container_main_right_requirement_titleBox">
      <div class="container_main_right_requirement_titleBox_title">Quotation</div>
      <div class="container_main_right_requirement_titleBox_left" @click="openCarbonValue">
        <img src="../../assets/images/home/carbonFootprint_white.png" alt="">
        <span>{{ thousands(quotation.totalco) }}kg</span>
        <span class="co">CO<sub>2</sub> </span>
        <img src="../../assets/images/customer/white_right.png" alt="">
      </div>
    </div>
      <!-- 详情 -->
  <div class="container_main_right_requirement_details" v-show="status === 1">
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Company Name</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.supplier?.supplierName }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">HS code</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.hscode }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Estimated Time of Delivery</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.productionDuration }}</div>
    </div>
    <div class="container_main_right_requirement_details_list" v-if="userType === '2' && orderType2 === 2">
      <div class="container_main_right_requirement_details_list_title">Actual Delivery Time</div>
      <div class="container_main_right_requirement_details_list_value">{{ actualDeliveryTime }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Sample Charge</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.sampleUnitPrice == null ? "" : currency }} {{ quotation.sampleUnitPrice }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Sample Lead Time</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.sampleGenerationPeriod }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Pcs/Ctn</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.pcsCtnSingle }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Carton Size (mm)</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.ctnSizeSingle }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Weight/Carton (kg)</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.weightCtnSingle }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Total CBM (m<sup>3</sup>)</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.totalCbm }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Gross Weight (kg)</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.totalGrossWeight }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">FOB/CIF (unit)</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.fobcif }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Special Charges</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.specialCharges }}</div>
    </div>
    <div class="container_main_right_requirement_details_list" v-if="quotation.specialCharges !== 'None'">
      <div class="container_main_right_requirement_details_list_title">Charge Amount</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.chargeAmount == null ? "" : currency }} {{ quotation.chargeAmount }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Material</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.ekoTpfsjChina?.catalog2
      }}
      </div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Material Usage</div>
      <div class="container_main_right_requirement_details_list_value">{{ quotation.materialUsage }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Unit Price</div>
      <div class="container_main_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.unitPrice) }}</div>
    </div>
    <div class="container_main_right_requirement_details_list">
      <div class="container_main_right_requirement_details_list_title">Price</div>
      <div class="container_main_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.totalprice) }}</div>
    </div>
    <!-- 不接受报价和接受报价按钮 -->
    <!-- <div class="container_main_right_requirement_details_list_btn" v-show="quotation.status !== 3 && quotation.status !== 4 && orderType === ''">

      <div class="container_main_right_requirement_details_list_btn_reject" @click="openContact">
        <img src="../../assets/images/customer/contact.png" alt="">
        Contact
      </div>
      <div class="container_main_right_requirement_details_list_btn_reject" @click="openRejectQuotation">
        Decline
      </div>
      <div class="container_main_right_requirement_details_list_btn_accept" @click="openSampleQuantity">
        Accept
      </div>
    </div> -->

    <!-- 已签大货单，不需要样品 -->
    <!-- <div class="container_main_right_requirement_details_list_status" v-show="orderType === 2">
      <img src="../../assets/images/supplier/check-circle.png" alt="">
      <span>Quotation accepted,awaitng mass production.</span>
    </div> -->

    <!-- 已签大货单，申请了样品 -->
    <!-- <div
      class="container_content_right_requirement_details_list_status"
      v-show="orderEntity?.orderType === 3"
    >
      <img
        src="../../assets/images/supplier/check-circle.png"
        alt=""
      >
      <span>Quote Accepted, waiting for sample shipment</span>
    </div> -->
    <!-- <div class="container_main_right_requirement_details_list_status" v-show="quotation.status === 3 && orderType !== 2">
      <img src="../../assets/images/supplier/check-circle.png" alt="">
      <span>Quote Accepted, waiting for sample shipment</span>
    </div> -->

    <!-- 已拒绝 -->
    <!-- <div class="container_main_right_requirement_details_list_status" v-show="quotation.status === 4">
      <img src="../../assets/images/supplier/emotion-unhappy.png" alt="">
      <span class="reject">this quotataion would be declined,you might net able to restore it.</span>
    </div> -->

    <!-- 只样品单 -->
    <!-- <div
      class="container_content_right_requirement_details_list_status"
      v-show="orderType === 1"
    >
      <img
        src="../../assets/images/supplier/check-circle.png"
        alt=""
      >
      <span>Quote Accepted, waiting for sample shipment</span>
    </div> -->
  </div>
  <div class="container_main_right_requirement_details" v-show="status === 2">

      <div v-show="status === 2" class="container_main_right_requirement_details_list" v-for="(item, index) in quotationData" :key="index">
        <div class="container_main_right_requirement_details_list_title" v-for="(value, key) in item" :key="key">{{
          key
        }}
        </div>
        <div class="container_main_right_requirement_details_list_value" v-for="(value, key) in item" :key="key">{{
          value
        }}
        </div>
      </div>
      <!-- 已拒绝大货单的报价 -->
      <div class="container_content_right_sampleOrder_details_list_status" v-show="status === 2">
        <img src="../../assets/images/supplier/emotion-unhappy.png" alt="">
        <span class="reject">Unaccepted quotation</span>
      </div>
    </div>

    </div>

  </div>

 </div>

    <!-- 查看碳值的弹窗 -->
    <ViewCarbonValue v-if="quotation.quotationId" v-on:customEvent="closeCarbonValue" v-show="showCarbonValue" :quantity="order.clientRequirement?.quantity" :quotation=quotation />

    <!-- foot   -->
    <Foot />
  </div>
</template>

<!-- 报价单详情 -->
<script setup name="SampleOrderDetail">
import { ref, reactive, provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Foot from '../../components/Foot.vue'
import url from '@/utils/URL'
import { saveAs } from 'file-saver'
import { getOrderDetail, downloadDocs } from '@/api/order'
import { getClientArtByRequirementId } from '@/api/demand'
import { thousands } from '@/utils/thousands'
import { getMaterialList, getSysDictList } from '@/api/user'
import { getUserType, getToken } from '@/utils/auth'
import { thousand } from '@/utils/thousand'
import axios from 'axios'
// 碳值弹窗
import ViewCarbonValue from './dialog/ViewCarbonValue.vue'
import { getDict } from '@/api/common'
import { getQuotationDetail } from '@/api/quotation'
import { ElMessage } from 'element-plus'
const router = useRouter()
const route = useRoute()
const requirementId = route.query.requirementId
const quotationId = route.query.quotationId
const orderId = route.query.orderId
const materialValue = ref()
const deliveryAddress = ref()
const currency = ref('')
// 切换图片
const imgList = ref([])
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png')

const materialId = ref()

// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

const downloadDocNew = async (val) => {
  // await downloadDocs(orderId)
  let url = ''
  if (val === '1') {
    if (fileList.length > 0) {
      url = fileList[0].url
    } else {
      ElMessage.error('Report not uploaded')
      return
    }
  } else if (val === '2') {
    if (fileList.length > 1) {
      url = fileList[1].url
    } else {
      ElMessage.error('Report not uploaded')
      return
    }
  }
  downLoadFile(url)
}

const downLoadFile = (url) => {
  const fileName = url.slice(url.lastIndexOf('/') + 1) // 这里是通过从后端获取到的 url 地址中截出来原本的文件名
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      // 下载文件的名称及文件类型后缀
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      // 在资源下载完成后 清除 占用的缓存资源
      window.URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
    })
}

const downloadDoc = async () => {
  // await downloadDocs(orderId)
  const downUrl = url.target + 'api/orderForGoods/exportDoc/' + orderId
  await axios({
    method: 'get',
    url: downUrl,
    responseType: 'blob',
    headers: { Authorization: 'Bearer ' + getToken() }
  }).then((res) => {
    const isBlob = blobValidate(res.data)
    if (isBlob) {
      const blob = new Blob([res.data])
      saveAs(blob, decodeURIComponent(res.headers['download-filename']))
    } else {
      console.log(res.data)
    }
  })
}

// 验证是否为blob格式
const blobValidate = (data) => {
  return data.type !== 'application/json'
}

// 产品2和后面的产品
let productInfoList = reactive([])
// 报告
let fileList = reactive([])

// 查询报价单
const quotation = ref({})
const order = ref({})
const orderDetail = async () => {
  const res = await getOrderDetail(orderId)
  if (res?.code === 200) {
    order.value = res.data
    fileList = order.value.fileList
    productInfoList = order.value.clientRequirement.productInfoList
    console.log('order', order.value)
    if (order.value.clientRequirement) {
      materialId.value = order.value.clientRequirement?.material
    }
    if (order.value.supplierQuotationList[0].currency) {
      currency.value = order.value.supplierQuotationList[0].currency
      if (order.value.supplierQuotationList[0].currency === 'EUR') {
        currency.value = '€'
      } else if (order.value.supplierQuotationList[0].currency === 'USD') {
        currency.value = '$'
      } else if (order.value.supplierQuotationList[0].currency === 'CNY') {
        currency.value = '¥'
      }
    }
  }
}
orderDetail()

const getDisplayedValue = (key) => {
  console.log('key', key.key)
  const value = order.value.clientRequirement[key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}
const getTitle = (key) => {
  return keys.value.find(item => item.key === key.key).name
}

// 产品2和后面的产品的value
const getDisplayedValue2 = (index, key) => {
  const value = productInfoList[index][key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

const getDictData = async (type, key) => {
  const res = await getDict({ type: type, key: key })
  if (res.code === 200) {
    materialValue.value = res.data
  }
}

const keys = ref([
  // { name: 'Product Name', key: 'productName' },
  { name: 'Quantity', key: 'quantity' },
  { name: 'Product Size (mm)', key: 'specification' },
  { name: 'Material', key: 'material' },
  { name: 'Colour', key: 'pantoneCode' },
  { name: 'Process Requirement', key: 'processRequirement' },
  { name: 'Other Requirement', key: 'otherSpecialRequirent' },
  { name: 'Remark', key: 'remark' }
  // { name: 'Overall Budget', key: 'overallBudget' },
  // { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  // {name:'Estimated Time of Delivery',key:'expectedDeliveryDate'},
  // { name: 'Delivery address', key: 'deliveryAddressId' }
  // { name: 'Sample requirment', key: 'needSample' }
])

// 通用数据
const commonData = reactive([
  // { name: 'Overall Budget', key: 'overallBudgetStr' },
  { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  { name: 'Delivery address', key: 'deliveryAddressId' }
])

const initData = () => {
  if (getUserType() === '2') {
    commonData.push({ name: 'Overall Budget', key: 'overallBudget' })
  }
}
initData()
// 获取通用数据标题
const getCommonTitle = (key) => {
  return commonData.find(item => item.key === key.key).name
}

// 当前报价单的状态，1：大货单已完成，可以再下一个订单 2：大货单未接受报价
const status = ref(1)

const quatationDetailId = reactive({
  id: 2356,
  orderType: 'sampleOrder',
  promptContent: ''
})
// 使用 provide 函数向弹窗提供数据
provide('quatationDetailId', quatationDetailId)

const quotationDetail = async () => {
  if (quotationId != null) {
    const result = await getQuotationDetail(quotationId)
    if (result?.code === 200) {
      quotation.value = result?.data
    }
  }
}
quotationDetail()

// 跳转签大货单
const goSignFormalOrder = () => {
  router.push({
    path: 'signFormalOrder',
    query: quatationDetailId
  })
}

const changeImg = (img) => {
  currentImgSrc.value = img
}

const requirementData = ref([
  { 'Product Name': 'Box - gift box' },
  { 'Product size': '23mm * 20mm * 40mm' },
  { Material: 'Lvory board' },
  { Color: 'Pantone（17-4911 TPX）' },
  { 'Proess Requirments': 'Concave-convex' },
  { 'Other specific requirments': '-' },
  { 'Overall Budget': '50000.00 EUR' },
  { 'Leadtime Requirment': 'May 1, 2023' },
  { 'Delivery address': 'Seaview Holiday Apartments Ireland' },
  { 'Sample requirment': 'Yes' }
])

// 报价单数据 ，未接受报价的
const quotationData = ref([
  { 'Customer Name': 'Baolong Paper Manufacturing Co Ltd' },
  { 'HS code': '48021090008' },
  { 'Official order delivery time': 'April 25, 2023' },
  { 'Sample charge': '200.00 EUR' },
  { 'Sample Leadtime': 'April 18, 2023' },
  { 'Pcs/Ctn': '50' },
  { 'CtnSize(mm)': '600mm * 400mm * 200mm' },
  { 'CBM/Ctn (m³)': '0.048' },
  { 'Weight/Ctn': '6.0kg' },
  { 'Total CBM': '9.6m³' },
  { 'Gross Weight(kg)': '1300kg' },
  { 'FOB/CIF (unit)': '180.00 EUR' },
  { 'Special charges': '-' },
  { Material: 'Paper' },
  { 'Material Usage': '2000kg' }
])

// 已完成订单的数据
const acceptQuotationData = ref([
  { Quantity: '100' },
  { 'CtnSize(mm)': '600mm * 400mm * 200mm' },
  { 'Unit-price': '10.00 EUR' },
  { 'Total price': '100000.00 EUR' },
  { 'Total time consumption': '60 Days' },
  { 'Expected carbon value': '526.08g' },
  { 'Actual carbon value': '569.23g' },
  { Difference: '+ 43.15g' },
  { 'Send demand time': 'April 18, 2023 16:23' },
  { 'Quotation return time': 'April 18, 2023 20:05  (+0.15 days)' },
  { 'Confirm order time': 'April 19, 2023 09:25  (+0.56 days)' },
  { 'Delivery time': 'April 28, 2023 16:18  (+9.29 days)' },
  { 'Arrival time at destination': 'June 06, 2023 20:02  (+39.16 days)' }
])

// 定义计算属性
const hasCarbonValue = (item) => {
  return Object.keys(item).some(key => key.toLowerCase().includes('carbon value'))
}

const hasDifference = (item) => {
  return Object.keys(item).some(key => key.includes('Difference'))
}

// 产品详情是否展开
const isShowRequirementDetail = ref(true)
const showRequirementDetail = () => {
  isShowRequirementDetail.value = !isShowRequirementDetail.value
  console.log(isShowRequirementDetail.value)
}

// 查看碳值的弹窗
const showCarbonValue = ref(false)
// 打开查看碳值的弹窗
const openCarbonValue = () => {
  showCarbonValue.value = true
}
// 关闭查看碳值的弹窗
const closeCarbonValue = (data) => {
  if (data === 'close') {
    showCarbonValue.value = false
  }
}

const getClientArtByRequirementIdMethod = async (requirementId) => {
  if (order.value != null) {
    if (order.value.clientRequirement != null) {
      const result = await getClientArtByRequirementId(order.value.clientRequirement.requirementId)
      if (result.code === 200) {
        if (result.code.data.length > 0) {
          for (const url of result.code.data) {
            downloadFile(url)
          }
        }
      }
    }
  }
}

const downloadFile = (url) => {
  const a = document.createElement('a')
  a.href = url // 文件链接
  a.download = url // 文件名，跨域资源download无效
  a.click()
  a.remove()
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #FFFFFF;
  line-height: 1;
  text-align: left;
  display: flex;
  flex-direction: column;

  &_content {
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    // 左侧图片
    &_left {
      width: 30%;
      font-size: 0;

      img {
        width: 500px;
        height: 500px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 6px;
      }

      &_imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 60px;
          height: 60px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.3;

          &:nth-child(5n) {
            margin-right: 0px;
          }
        }

        &_isSelected {
          opacity: 1 !important;
          box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .5);
        }
      }
      &_btnAll{
        display: flex;
        flex-direction: column;
        align-items: center;
        &_item{
          margin-top: 20px;
          color: rgba(39, 174, 96, 1);
          padding: 25px 50px;
          border: 1px solid;
          border-color: rgba(39, 174, 96, 1);
          border-radius: 6px;
          font-size: 20px;
          text-align: center;
        }
      }
      &_btn{
        background-color: #169BD5;
        margin-top: 20px;
        color: #ffffff;
        padding: 25px 50px;
        border: 1px solid;
        border-color: rgba(39, 174, 96, 1);
        border-radius: 6px;
        font-size: 20px;
        text-align: center;
      }
      &_btnNo{
        margin-top: 20px;
        background-color: darkgray;
        color: #ffffff;
        padding: 25px 50px;
        border: 1px solid;
        border-color: rgba(39, 174, 96, 1);
        border-radius: 6px;
        font-size: 20px;
        text-align: center;
      }
    }

    &_right {
      width: 66%;
      &_sampleOrder {

        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(180deg, rgba(39, 174, 96, 0.1) 0%, rgba(39, 174, 96, 0) 100%);
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }

          &_quantity {
            font-size: 20px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {
          background-image: url(../../assets/images/bg.svg);
          background-repeat: no-repeat;
          background-size: 500px 500px;
          background-position: center right;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            // border-top: 1px dashed rgba(39, 174, 96, .6);
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &_title {
              font-size: 20px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 20px;
              width: 200px;
            }

            &_value {
              margin-left: 20px;
              font-size: 20px;
              color: rgba(24, 24, 24, 1);

              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }

              .co {
                color: rgba(36, 89, 2, 0.5);
                font-size: 12px;
                margin-left: 5px;
              }
            }

            .carbon {
              color: rgba(39, 174, 96, 1);
              font-weight: 700;

              .co {
                font-weight: 300;
              }
            }

            .difference {
              color: rgba(255, 74, 38, 1);
              font-weight: 700;

              .co {
                font-weight: 300;
              }
            }

            &:last-child {
              // border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 20px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, .6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 20px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }

        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #FFFFFF;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 200px;

        .container_content_right_sampleOrder_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #FFFFFF;

              &+.co {
                font-size: 20px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }

        // 报价单状态
        .container_content_right_sampleOrder_details_list_status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 0;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }

          span {
            font-size: 20px;
            font-weight: 700;
            color: rgba(39, 174, 96, 1);
            padding: 15px 0px;
          }

          .btn {
            background-color: rgba(39, 174, 96, 1);
            color: #FFFFFF;
            padding: 20px 30px;
            display: inline-block;
            border-radius: 6px;
            cursor: pointer;
          }

          .reject {
            color: rgba(255, 151, 0, 1);
          }
        }
      }
    }
  }
  &_main{
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    &_title{
      border: 1px rgb(12, 150, 69) solid;
      border-radius: 10px;
      padding: 20px 20px 20px 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      &_info{
        background-color: rgb(255, 151, 0);
        color: white;
        padding: 20px 30px;
        border-radius: 10px;
        border: 1px rgb(255, 151, 0) solid;
      }
      &_infounselect{
        color: rgb(255, 151, 0);
        padding: 20px 30px;
        border-radius: 10px;
        border: 1px rgb(255, 151, 0) solid;
      }
    }
    &_right {
      width: 100%;
      &_requirement {

        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%);
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }

          &_quantity {
            font-size: 20px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {
          // margin-top: -10px;
          background-image: url(../../assets/images/bg.svg);
          background-repeat: no-repeat;
          background-size: 500px 500px;
          background-position: center right;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            // border-top: 1px dashed rgba(39, 174, 96, .6);
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &_title {
              font-size: 20px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 20px;
              width: 200px;
            }

            &_value {
              margin-left: 20px;
              font-size: 20px;
              color: rgba(24, 24, 24, 1);
            }

            &:last-child {
              // border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 20px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, .6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 20px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #ffffff;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 200px;

        .container_main_right_requirement_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #ffffff;

              &+.co {
                font-size: 20px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }

        // 底部按钮
        .container_content_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 20px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

 // 底部按钮
 .container_main_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 20px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

        // 报价单状态
        .container_main_right_requirement_details_list_status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 0;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }

          span {
            font-size: 20px;
            font-weight: 700;
            color: rgba(39, 174, 96, 1);
            padding: 15px 0px;
          }

          .reject {
            color: rgba(255, 151, 0, 1);
          }
        }
      }

      &_btn {
        display: flex;
        margin-bottom: 60px;
        justify-content: center;

        .back_btn {
          font-size: 20px;
          font-weight: 700;
          padding: 20px 30px;
          margin: 0 25px;
          border-radius: 4px;
          cursor: pointer;
        }

        &_back {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(39, 174, 96, 1);
        }
      }
    }

    }
}
</style>
